/** Add Route Component */
import Layout1 from "../pages/Layout1/Layout1";
//import Layout2 from "../pages/Layout2/Layout2";
//import Layout3 from "../pages/Layout3/Layout3";

interface RouteProps {
  path: string;
  component?: any;
  exact?: boolean;
}

const Routes: Array<RouteProps> = [
  { path: "/", component: Layout1 },
];

export default Routes;
