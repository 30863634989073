import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

//import images
import logo1 from "../assets/images/techlogo/bigquery.png";
import logo2 from "../assets/images/techlogo/gcp.png";
import logo3 from "../assets/images/techlogo/spring-boot.png";
import logo4 from "../assets/images/techlogo/cubejs.png";

import logo5 from "../assets/images/techlogo/looker.png";
import logo6 from "../assets/images/techlogo/tableau.png";
import logo7 from "../assets/images/techlogo/powerbi.png";
import logo8 from "../assets/images/techlogo/qgis.png";

import logo9 from "../assets/images/techlogo/tensorflow.png";
import logo10 from "../assets/images/techlogo/scikit-learn.png";
import logo11 from "../assets/images/techlogo/camel.png";
import logo12 from "../assets/images/techlogo/bpmn.png";

const Tech = () => {
  return (
    <React.Fragment>
      <section className="section bg-light" id="tech">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="text-center">
              <h4 className="fs-24">Tecnologias Utilizadas</h4>
                <p className="fs-16 text-muted mt-3">
                  Utilizamos o que há de mais moderno em tecnologias de
                  gestão e análise de dados e desenvolvemos soluções sob
                  demanda para as necessidades da sua empresa.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg={3}>
              <div className="mt-4">
                <img src={logo1} className="img-fluid rounded-3" alt="" />
                
              </div>
            </Col>
            <Col lg={3}>
              <div className="mt-4">
                <img src={logo2} className="img-fluid rounded-3" height={50} alt="" />
                
              </div>
            </Col>
            <Col lg={3}>
              <div className="mt-4">
                <img src={logo3} className="img-fluid rounded-3" alt="" />
                
              </div>
            </Col>
            <Col lg={3}>
              <div className="mt-4">
                <img src={logo4} className="img-fluid rounded-3" alt="" />
                
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={3}>
              <div className="mt-4">
                <img src={logo5} className="img-fluid rounded-3" alt="" />
                
              </div>
            </Col>
            <Col lg={3}>
              <div className="mt-4">
                <img src={logo6} className="img-fluid rounded-3" height={50} alt="" />
                
              </div>
            </Col>
            <Col lg={3}>
              <div className="mt-4">
                <img src={logo7} className="img-fluid rounded-3" alt="" />
                
              </div>
            </Col>
            <Col lg={3}>
              <div className="mt-4">
                <img src={logo8} className="img-fluid rounded-3" alt="" />
                
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={3}>
              <div className="mt-4">
                <img src={logo9} className="img-fluid rounded-3" alt="" />
                
              </div>
            </Col>
            <Col lg={3}>
              <div className="mt-4">
                <img src={logo10} className="img-fluid rounded-3" height={50} alt="" />
                
              </div>
            </Col>
            <Col lg={3}>
              <div className="mt-4">
                <img src={logo11} className="img-fluid rounded-3" alt="" />
                
              </div>
            </Col>
            <Col lg={3}>
              <div className="mt-4">
                <img src={logo12} className="img-fluid rounded-3" alt="" />
                
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Tech;
