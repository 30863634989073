import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

//import images
import logoLight from "../assets/images/logo-dark-transparent.png";

const Footer = () => {
  return (
    <React.Fragment>
      <section className="footer text-muted">
        <Container>
          <Row>
            <Col lg={4}>
              <div className="">
                <Link className="logo d-inline-block mb-3" to="/">
                  <img
                    src={logoLight}
                    className="logo-light"
                    alt=""
                    height="32"
                  />
                </Link>
                <p className="fs-16">
                  Consultoria em estratégia e tecnologia da informação, análise de dados,
                  business intelligence, gestão e governança de processos de negócio,
                  alinhamento estratégico.
                </p>
                <div className="text-white">
                  
                </div>
              </div>
            </Col>

            <Col lg={2} className="offset-lg-1">
              
            </Col>

            <Col lg={2}>
             
            </Col>

            <Col lg={3}>
              
            </Col>
          </Row>
        </Container>
      </section>

      <div className="footer-alt">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center text-white-50 footer-alt py-3">
                <p className="mb-0">
                  <script>document.write(new Date().getFullYear())</script> ©
                  Meta Estratégica Consultoria LTDA <br/>
                  CNPJ 46.360.140/0001-71
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Footer;
