import React from "react";
import { Col, Container, Row } from "reactstrap";

const Features = () => {
  // features
  const featuresItem = [
    {
      id: 1,
      icon: "uil uil-clipboard",
      title: "Monitoramento",
      desc: "Tenho sistemas, mas não estou coletando dados. Como coletar dados úteis sobre minha empresa e meus clientes?",
    },
    {
      id: 2,
      icon: "uil uil-books",
      title: "Governança",
      desc: "Já tenho os dados, mas estão espalhados em várias plataformas e não têm padronização. Como melhoro minha governança de dados?",
    },
    {
      id: 3,
      icon: "uil uil-data-sharing",
      title: "Pipeline de Dados",
      desc: "Meus dados são bons, mas não estão facilmente acessíveis. Como faço para que meus dados estejam disponíveis no momento em que preciso?",
    },
    {
      id: 4,
      icon: "uil uil-dollar-sign",
      title: "Agregar Valor",
      desc: "Meus dados estão todos acessíveis, mas não estão dando nenhum retorno financeiro. Como faço para extrair informação de valor dos meus dados?",
    },
    {
      id: 5,
      icon: "uil uil-trophy",
      title: "Competitividade",
      desc: "Como utilizo a informação estrategicamente para ganhar vantagem competitiva?",
    },
    {
      id: 6,
      icon: "uil uil-bullseye",
      title: "Alinhamento Estratégico",
      desc: "Como faço para garantir que minha operação está em linha com minhas metas estratégicas?",
    },
  ];
  return (
    <React.Fragment>
      <section className="section" id="service">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="text-center">
                <h4 className="fs-24">Soluções tecnológicas para sua vantagem competitiva</h4>
                <p className="text-muted fs-16 mt-3">
                  Transforme os dados de sua organização em vantagem competitiva.
                  Ganhe agilidade na tomada de decisão e alcance alinhamento estratégico
                  mesmo em cenários de incerteza.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="pt-5">
            {(featuresItem || []).map((features: any, key: number) => (
              <Col lg={4} md={6} key={key}>
                <div className="mt-4">
                  <div className="d-flex">
                    <div className="features-icon text-center">
                      <i className={features.icon}></i>
                    </div>
                    <div className="flex-1 ms-4">
                      <h5 className="mt-0 fs-16">{features.title}</h5>
                      <p className="text-muted">{features.desc}</p>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Features;
