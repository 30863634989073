import React from "react";
import { Col, Container, Row } from "reactstrap";

//import images
import cesar from "../assets/images/team/profile.jpg";
import team2 from "../assets/images/team/2.jpg";
import team3 from "../assets/images/team/3.jpg";
import team4 from "../assets/images/team/4.jpg";

const Team = () => {
  return (
    <React.Fragment>
      <section className="section" id="about">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="about-title mx-auto text-center">
                <h2 className="fw-light lg-lg">
                  Experiência
                </h2>
                <p className="text-muted fs-17 lh-base pt-4">
                  Nossa empresa tem atuação internacional, em diferentes setores do mercado,
                  incluindo comércio, justiça, educação, transportes e políticas públicas na área social.                  
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg={1}>

            </Col>  
            <Col lg={3}>
              <div className="mt-3">
                <img
                  src={cesar}
                  alt=""
                  className="img-fluid team-member mx-auto d-block
                                rounded-circle img-thumbnail"
                />
                <div className="text-center mt-3">
                  <p className="fw-medium fs-16 mb-0">Cesar A. Lins de Oliveira</p>
                  <p className="text-muted fs-14 mb-0">Consultor-Chefe</p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mt-3">
                <p className="text-muted fs-15 lh-base pt-3">Possui doutorado em ciências da computação pela
                  Universidade Federal de Pernambuco, com vários artigos publicados na área de
                  melhoria de processos de negócio e alinhamento estratégico organizacional.
                  Atuou por 9 anos como consultor na área de dados do Banco Interamericano de Desenvolvimento,
                  trabalhando em projetos inovadores do setor social e ajudando na formulação da
                  metodologia de governança de dados do setor. Foi ainda responsável pela elaboração
                  da Estratégia para Equidade Étnico-Racial do CAF - Banco de Desenvolvimento da América Latina.
                  Tem experiência como desenvolvedor full-stack e em ciência de dados.</p>
              </div>
            </Col>
            <Col lg={2}>

            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Team;
