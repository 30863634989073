import Icon from "@ailibs/feather-react-ts";
import React from "react";
import { Col, Container, Row } from "reactstrap";

const ContactUs = () => {
  return (
    <React.Fragment>
      <section className="section" id="contact">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="contact-head text-center">
                <h4 className="fs-24">Contato</h4>
                <p className="fs-16 text-muted lh-base mt-3">
                  Somos uma empresa baseada em Recife, mas com atuação internacional.
                  Não importa onde esteja sua organização, envie-nos um e-mail e
                  entraremos em contato para desenhar uma solução para o seu negócio.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg={12}>
              <Row className="mt-5">
              <Col lg={4}></Col>
                <Col lg={4}>
                  <div className="">
                    <div className="mt-3">
                      <div className="float-start">
                        <Icon
                          name="mail"
                          className="avatar-s me-2 align-middle"
                        />
                      </div>
                      <div>
                        <h6 className="text-dark fs-16">E-mail</h6>
                        <p className="text-muted fs-16">contato@metaestrategica.com.br</p>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="float-start">
                        <Icon
                          name="phone-call"
                          className="avatar-s me-2 align-middle"
                        />
                      </div>
                      <div>
                        <h6 className="text-dark fs-16">Telefone</h6>
                        <p className="text-muted fs-16">
                          +55 (81) 99934-7758
                        </p>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="float-start">
                        <Icon
                          name="map-pin"
                          className="avatar-s me-2 align-middle"
                        />
                      </div>
                      <div>
                        <h6 className="text-dark fs-16">Endereço</h6>
                        <p className="text-muted fs-16">
                          Av Antonio Torres Galvão, 221 CXPST 1481 <br />
                          Recife, PE, Brasil 51160-330
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg={4}>
                  
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ContactUs;
