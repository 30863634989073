import React from "react";
import { withRouter } from "react-router-dom";
import ContactUs from "../components/ContactUs";
import Cta from "../components/Cta";
import Features from "../components/Features";
import Tech from "../components/Tech";
import Team from "../components/Team";
import Footer from "./Footer";
import Navbar from "./Navbar";

const Layout = (props: any) => {
  return (
    <React.Fragment>
      {/* import Navbar */}
      <Navbar />

      {/* page content */}
      {props.children}

      {/* import Feaures */}
      <Features />


      {/* imoport Latest News  */}
      <Tech />

      {/* import Team */}
      <Team />

      {/* import Cta */}
      <Cta />

      {/* import Contact Us  */}
      <ContactUs />

      {/* import Footer */}
      <Footer />
    </React.Fragment>
  );
};

export default withRouter(Layout);
