import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const Cta = () => {
  return (
    <React.Fragment>
      <section className="section bg-cta">
        <div className="bg-overlay"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <h3 className="text-center text-white">
                Que tal começar a criar valor para a sua empresa?
              </h3>
              <p className="text-white-50 text-center lh-base fs-16 mt-3">
                Entre em contato para fazermos uma avaliação das demandas
                e potencialidades da sua empresa.
              </p>
              <div className="text-center mt-4">
                <Link to="javascript:void(0)" onClick={(e) => {
                    window.location.href = 'mailto:contato@metaestrategica.com.br';
                    e.preventDefault();
                }} className="btn btn-primary">
                  Enviar um E-mail <i className="mdi mdi-arrow-right"></i>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Cta;
