import React, { useState } from "react";
import { Collapse, NavbarToggler, NavLink } from "reactstrap";
import Scrollspy from "react-scrollspy";

//import images
import logoLight from "../assets/images/logo-dark-transparent.png";
import logoDark from "../assets/images/logo-color-transparent.png";

const NavBar = (props: any) => {
  const [isOpenMenu, setisOpenMenu] = useState<boolean>(false);

  const toggle = () => setisOpenMenu(!isOpenMenu);

  return (
    <React.Fragment>
      <nav
        className="navbar navbar-expand-lg fixed-top nav-sticky sticky"
        id="navbar"
      >
        <div className="container">
          <a className="navbar-brand logo" href="#home">
            <span className="logo-light-mode">
              <img src={logoLight} className="logo-light" alt="" height="32" />
              <img src={logoDark} className="logo-dark" alt="" height="32" />
            </span>
            <img
              src={logoLight}
              className="logo-dark-mode"
              alt=""
              height="32"
            />
          </a>
          <NavbarToggler
            className="navbar-toggler collapsed"
            type="button"
            onClick={toggle}
          >
            <i className="mdi mdi-menu"></i>
          </NavbarToggler>
          <Collapse
            isOpen={isOpenMenu}
            className="navbar-collapse"
            id="navbarNav"
          >
            <Scrollspy
              offset={-10}
              items={[
                "home",
                "service",
                "tech",
                "about",
                "contact",
              ]}
              currentClassName="active"
              className="navbar-nav ms-auto navbar-center"
              id="mySidenav"
            >
              <li className="nav-item">
                <NavLink href="#home">Início</NavLink>
              </li>
              <li className="nav-item">
                <NavLink href="#service">Serviços</NavLink>
              </li>
              <li className="nav-item">
                <NavLink href="#tech">Tecnologias</NavLink>
              </li>
              <li className="nav-item">
                <NavLink href="#about">Sobre</NavLink>
              </li>
              <li className="nav-item">
                <NavLink href="#contact">Contato</NavLink>
              </li>
            </Scrollspy>
          </Collapse>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default NavBar;
